@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --primary-50: #e3fffd;
  --primary-100: #b8fffb;
  --primary-200: #7bfffb;
  --primary-300: #1efffd;
  --primary-400: #00b5bc;
  --primary-500: #008f9b;
  --primary-600: #007282;
  --primary-700: #005967;
  --primary-800: #074754;
  --primary-900: #093b47;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-headless-notifier-fixed {
  z-index: 999;
}

.fc-list-event-time {
  display: none;
}

.custom-pin {
  /* Set the marker size here */
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  /* Set the marker color here */
  background: #138200;

  display: inline-block;
  border-bottom-right-radius: 0;
  position: relative;
  transform: rotate(45deg);

  /* optional fanciness */
  border: 1px solid #138200;
}

/* inner circle (optional if you don't need the central dot) */
.custom-pin::before {
  content: "";
  background: white;
  width: 50%;
  height: 50%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  /* optional fanciness */
  box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}

.driver-tracker {
  /* Set the marker size here */
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  /* Set the marker color here */
  background: #224b96;

  display: inline-block;
  /* border-bottom-right-radius: 0;
  position: relative;
  transform: rotate(45deg); */

  /* optional fanciness */
  border: 1px solid #224b96;
}

/* inner circle (optional if you don't need the central dot) */
.driver-tracker::before {
  content: "";
  background: white;
  width: 40%;
  height: 40%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  /* optional fanciness */
  box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}


.print-table {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  print-color-adjust: exact !important;
}


.print-table table,
.print-table th,
.print-table td {
  padding: 5px;
  border: 1px solid black;
  border-collapse: collapse;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.print-table .truck,
.print-table th {
  background-color: #dbeafe;
}

.awaiting {
  /* background-color: #FF0000; */
}

.export {
  background-color: #ffff00;
}

.sales {
  background-color: #86dc3d;
}

.scrap {
  background-color: #90bbe8;
}

.cancelled {
  background-color: #e97451;
}

.salvage {
  background-color: #b951e9;
}

/* --- ---
These segments change the Pintura yellow "Done" button to look slightly more useful 
The button is larger, bluer, and says "Save" instead of "Done"
*/
.PinturaButton.PinturaButtonExport {
  background-color: #025196 !important;
  color: white !important;
}

.PinturaButton.PinturaButtonExport span.PinturaButtonInner span.PinturaButtonLabel {
  text-indent: -9999px !important;
  line-height: 0 !important;
}

.PinturaButton.PinturaButtonExport span.PinturaButtonInner span.PinturaButtonLabel::after {
  content: "Save" !important;
  text-indent: 0 !important;
  display: block !important;
  line-height: initial !important;
  padding: 25px !important;
  font-weight: bold !important;
}

/* --- --- */


/* Scrollbar CSS */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

/* Handle on click and hold */
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.6);
}


/* --- Interactive Map --- */
.hoverable-path {
  transition: fill-opacity 0.3s ease;
  /* Smooth transition */
}

.hoverable-path:hover {
  fill-opacity: 1 !important;
  fill: #5957f5 !important;
  cursor: pointer !important;
}